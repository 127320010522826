@import "variables";

.wak-vc-addon-wak_vc_latest_news {
  width: 100%;
  max-width: $container__max-width;
  padding: 0 $container__gutter;
  margin: 0 auto;
}

.wak-latest-news-title {
  text-align: center;
  margin-bottom: 60px;
}

.wak-latest-news-posts-post {
  margin: 15px 0;
}

.wak-latest-news-posts-post-image-link {
  display: block;
  margin-bottom: 15px;
}

.wak-latest-news-posts-post-image {
  display: block;
  width: 100%;
}

.wak-latest-news-posts-post-category {
  display: inline-block;
  background: $magenta;
  color: $white;
  padding: 7px 10px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.05em;
  margin-bottom: 15px;
}

.wak-latest-news-posts-post-title {
  color: $base__colour;
  font-size: $h5__font-size;
  line-height: $h5__line-height;
  margin-bottom: 15px;
  &:hover{
    color: $mid-green;
  }
}

.wak-latest-news-posts-post-link {
  color: $base__colour;
  text-decoration: $link__text-decoration;
  text-decoration-thickness: $link__text-decoration-thickness;
  text-underline-offset: $link__text-underline-offset;
}

.wak-latest-news-pagination {
  text-align: center;
  margin-top: 50px;
  padding-bottom: 10px;

  .page-numbers {
    margin: 0 16px;
    color: $dark-green;

    &.disabled {
      color: $form-border-blue;
    }

    &.current {
      background: $dark-green;
      color: $white;
      padding: 4px 10px;
      margin: 0 11px;
      border-radius: 50%;
    }
  }
}
.wak-latest-news-image-link-wrapper{
  position: relative;
  .wak-latest-news-posts-post-category{
    position: absolute;
    bottom: 0;
    left: 0;
    margin-bottom: 0;
  }
}