.wak-vc-addon-video {
    display: flex;
    flex-direction: column;
    border-radius: .4rem;
    overflow: hidden;
    &.medium {
        max-width: 770px;
    }
}

.video__player {
    position: relative;
    padding-top: 56.25%;
}

.video__asset {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: block;
    padding: 0;
    border: none;
    cursor: pointer;
}