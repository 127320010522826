@import "variables";

.wak-vc-addon-wak_vc_image_and_quote {
  width: 100%;
  max-width: $container__max-width;
  padding: 0 $container__gutter;
  margin: 0 auto;
}

.wak-image-quote--image-right {
  flex-direction: row-reverse;
}

.wak-image-quote-image-wrapper {
  margin-bottom: 40px;
}

.wak-image-quote-image {
  display: block;
  width: 100%;
}

.wak-image-quote-quotes {
  display: block;
  margin-bottom: 20px;
}

.wak-image-quote-content {
  //font-weight: $h4__font-weight;
  font-size: $h4__font-size;
  line-height: $h4__line-height;
  color: $base__colour;

  > *:last-child {
    margin-bottom: 0;
  }
}

.wak-image-quote-author h3 {
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 1.7rem;
  line-height: 2.4rem;
  color: $base__colour;
}

.wak-image-quote-content-button-wrapper {
  margin-top: 30px;
}

// > 768

@media only screen and (min-width: map-get($breakpoints, md)) {
  .wak-image-quote-image-wrapper {
    margin-bottom: 0;
  }
}

// > 1024
@media only screen and (min-width: map-get($breakpoints, lg)) {
  .wak-image-quote-content-wrapper {
    padding-top: 60px;
    // padding-right: 80px;
    // padding-left: 100px;
  }

  .wak-image-quote--left {
    .wak-image-quote-content-wrapper {
      padding-left: 100px;
    }
  }

  .wak-image-quote--right {
    .wak-image-quote-content-wrapper {
      padding-right: 100px;
    }
  }
}