@import "variables";

.stats-and-copy-row-wrapper {
  width: 100%;
  max-width: $container__max-width;
  padding: 0;
  margin: 0 auto;
}

.wak-vc-addon-wak_vc_stats_and_copy {
  margin: 15px 0;
}