@import "variables";

.wak-vc-addon.wak-vc-addon-wak_vc_download_file {
  background-color: $light-green;
  padding: map-get($spacers, 4) $container__gutter;
  display: flex;
  flex-direction: column;
  align-items: center;

  .button {
    background-color: $black !important;
    color: $white !important;
  }
  a {
    color: $black;
    &:hover {
      color: $black;
    }
  }
  &--green {
    background-color: $light-green;
  }
  &--yellow {
    background-color: $yellow;
  }
  &--orange {
    background-color: $orange;
  }
  &--magenta {
    background-color: $magenta;
  }
  &--orange, &--magenta {    
    color: $white;
    a {
      color: $white;
      &:hover {
        color: $white;
      }
    }
    .wak-download-file-title, .ginput_container_consent .gfield_consent_label {
      color: $white;
    }
    .gform_wrapper .gfield_required {
      color: $white !important;
    }
    .gform_wrapper form .gform_body .gform_fields .gfield .gsection_title {
      color: $white;
    }
  }
}

.wak-download-file-title-wrapper,
.wak-download-file-content {
  width: 100%;
  max-width: 770px;
  text-align: center;
}

.wak-download-file-form {
  width: 100%;
  max-width: 490px;
}

.wak-download-file-content > *:last-child {
  margin-bottom: 0;
}

.wak-download-file-title {
  color: $base__colour;
}