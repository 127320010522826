.widget_wak_newsletter_widget {
  width: 100%;
  max-width: 930px;
  padding: 0 $container__gutter;
  margin-top: map-get($spacers, 3);
  margin-bottom: map-get($spacers, 5);
  margin-left: auto;
  margin-right: auto;

  .fui-checkbox {
    border: none;
    label {
      font-weight: 400 !important;
    }
  }
}

.newsletter-icon {
  display: block;
  margin: 0 auto 10px;
}

.wak-newsletter-widget-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: $h4__font-size;
  line-height: $h4__line-height;
}

@media only screen and (min-width: map-get($breakpoints, md)) {
  .widget_wak_newsletter_widget {

    .fui-page {
      display: flex;
    }

    .fui-page-container {
      display: flex;
      position: relative;
    }

    .fui-field {
      min-width: 100%; //override form style
      margin-bottom: 0; //override form style
      margin-right: 1.6rem;
    }

    .fui-page-row {
      flex-grow: 1;
      margin: 0; //override form style
      padding-right: 1.6rem;
      width: auto !important;
      justify-content: flex-start;

      &:last-child {
        // Agree field
        position: absolute;
        bottom: -60px;
        width: calc(100% + 135px) !important;
        padding-left: 0;
      }
    }

    .fui-type-agree {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    .fui-btn-wrapper {
      display: flex;
      justify-content: center;
      margin: 0;
      flex-grow: 0;
      height: fit-content;
      align-self: end;
      margin-left: 1rem;
      button {
        margin: 0;
      }
    }
  }
}
