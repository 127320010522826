label {
  margin: 1rem 0;
  display: inline-block;
  font-size: $field-label__font-size !important;
  line-height: $field-label__line-height !important;
  font-weight: $field-label__font-weight !important;
  &.hidden-label {
    margin: 0;
  }
}

input[type="date"],
input[type="email"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="url"],
select,
textarea {
  width: 100%;
  padding: 1.1rem 1.2rem;
  border: solid 1px $black;
  background-color: $white;
  font-weight: 400;
  color: $black;
  border-radius: 0;

  &:active, &:focus {
    outline: none;
    -webkit-box-shadow: inset 0 0 0 2px $orange;
    -moz-box-shadow: inset 0 0 0 2px $orange;
    box-shadow: inset 0 0 0 2px $orange;
    color: $black;
  }
}

input[type="checkbox"],
input[type="radio"] {
  margin-right: 1.6rem;
}

.custom-select {
  position: relative;
  
  select {
    appearance: none;
    margin: 0;
    padding-right: 52px;
    cursor: pointer;
  }

  select::-ms-expand {
    display: none;
  }

  &:after {
    content: "";
    width: 28px;
    height: 28px;
    border-radius: 100%;
    color: $white;
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    background-repeat: no-repeat;
    background-position: center calc(50% + 2px);
    background-image: url("/dist/img/dropdown-icon.png");
    background-size: 12.25px auto;
    pointer-events: none;
  }
}

// Custom checkboxes & radios
.custom-input-label {
  position: relative;
  padding-left: 2.6rem;
  padding-top: 0;
  font-weight: 400;
  margin: 0;
  font-size: 1.8rem;
  line-height: 2.6rem;
  cursor: pointer;

  input[type="checkbox"],
  input[type="radio"] {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
  }
}

.custom-checkbox,
.custom-radio {
  position: absolute;
  top: 0;
  left: 0;
  width: 1.6rem;
  height: 1.6rem;
  border: solid 1px $black;
  background-color: $white;
}

.custom-checkbox {
  background-repeat: no-repeat;
  background-position: center;
}

.custom-radio {
  border-radius: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

input[type="checkbox"]:focus ~ .custom-checkbox,
input[type="radio"]:focus ~ .custom-radio {
  -webkit-box-shadow: inset 0 0 0 2px $orange;
  -moz-box-shadow: inset 0 0 0 2px $orange;
  box-shadow: inset 0 0 0 2px $orange;
}

input[type="checkbox"]:checked ~ .custom-checkbox {
  background-image: url("/dist/img/tick.svg");
  background-size: 1rem auto;
  border: 0;
  background-color: $black;
}

input[type="radio"]:checked ~ .custom-radio {
  &:before {
    content: "";
    width: 1rem;
    height: 1rem;
    background-color: $black;
    border-radius: 100%;
    display: block;
  }
}

// Fields put in a loading state by ajax sprig requests
input.htmx-request {
  opacity: .5;
  cursor: wait;
  pointer-events: none;
}

//--------//
// Formie //
//--------//

.fui-legend,
.fui-input-container,
.fui-instructions {
  margin: 1rem 0;
  font-size: $field-label__font-size;
  line-height: $field-label__line-height;
}

.fui-legend {
  font-weight: $field-label__font-weight;
}

.fui-row {
  margin: 0 -1rem;
  flex-direction: column;
}

.fui-field {
  margin: 0 1rem 2rem 1rem;
}

.fui-field-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.fui-input-container {
  margin-top: 0.8rem;
  width: 100%;

  p, li {
    margin-bottom: 0.8rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
  ul {
    list-style-type: disc;
    padding-left: 2rem;
    margin: 0;
  }
}

.fui-fieldset {
  padding: 0;
  border: 0;
}

// Fields that have multiple sub-fields don't want their own bottom margin
.fui-subfield-fieldset {
  margin-bottom: -2rem;
}

.fui-checkbox,
.fui-radio {
  background-color: $white;
  width: 100%;
  padding: 10px 10px 7px 10px;
  border: 1px solid $black;
  border-radius: 3px;
  color: $black;
  margin-bottom: 1rem;
}

// Formie errors
.fui-alert {
  padding: 1.8rem 3.2rem;
  border-radius: .8rem;
  border: 3px solid $black;

  &.fui-alert-top-form {
    margin-bottom: 8rem;
  }

  &.fui-alert-error {
    border-color: red;
  }

  &.fui-alert-success {
    border-color: green;
  }
}

.fui-input.fui-error {
  border: solid 1px red;
  box-shadow: inset 0 0 0 2px red;
}

.fui-field.fui-error {
  padding-left: 1.6rem;
  border-left: 3px solid red;
  padding-bottom: 1rem;
}

.fui-error-message {
  color: red;
  font-weight: 500;
  margin-top: 1rem;
}

.fui-repeater-row {
  margin-bottom: 2rem;
  padding: 1.5rem;
  border-radius: .4rem;
  border: 2px dashed $black;
}

.fui-type-signature {
  canvas {
    background-color: $black;
    border: solid 2px $black;
    width: 100%;
    height: 150px;
    border-radius: .4rem;
  }
}

.fui-type-calculations input[type="text"] {
  padding: 0;
  background-color: transparent;
  box-shadow: none;
  color: $black;
}

.fui-prev {
  margin-right: 1rem;
}

.fui-tabs {
  display: none;
}

.fui-progress-container {
  margin-bottom: 2.5rem;
  margin-top: 2rem;
}

.fui-progress,
.fui-progress-bar {
  border-radius: .8rem;
  height: 16px;
}

.fui-progress-label {
  //@extend h4;
  margin-bottom: 0;
}

.fui-progress {
  font-size: 0;
  background-color: #f2f2f2;
  margin-top: 2rem;
}

.fui-progress-bar {
  background-color: $black;
}

.fui-label-below-input label,
.fui-instructions-below-input .fui-instructions  {
  margin-bottom: 0;
  margin-top: 1rem;
}

.fui-layout-horizontal {
  .fui-checkbox,
  .fui-radio {
    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
}

.fui-btn {
  margin-top: 1.6rem;
}

// International phone number dropdown
.fui-i .fui-form .iti--separate-dial-code {
  .iti__flag-container {
    padding: 2px;
  }

  .iti__flag-box,
  .iti__flag {
    display: none;
  }

  .iti__selected-flag {
    display: flex;
    padding: 0 12.5px 0 10.5px;

    &:not([title]):after {
      content: "Country";
      display: block;
      margin-left: 1rem;
      margin-top: 1px;
      color: $black;
    }
  }

  .iti__arrow {
    border: 0;
    margin: 0;
    width: 32px;
    height: 32px;
    border-radius: 100%;
    background-image: url("/dist/img/dropdown-icon.png");
    background-repeat: no-repeat;
    background-position: center calc(50% + 2px);
    background-size: 12.25px auto;

    &.iti__arrow--up {
      transform: rotate(180deg);
      background-position: center;
    }
  }

  .iti__selected-dial-code {
    order: 3;
    margin-left: 1rem;
    margin-top: 1px;
    font-size: inherit;
    color: $black;
  }

  .iti__country-list {
    border: 0;
    border-radius: .4rem;
    color: $black;

    li {
      padding: 1.2rem 1.2rem 1.2rem 1.6rem;
      margin: 0;
    }
  }
}

@media screen and (min-width: map-get($breakpoints, md)) {

  .fui-row  {
    flex-direction: row;

    .fui-subfield-fieldset & {
      flex-direction: row;
      margin-top: 0;
  
      .fui-name-prefix {
        flex: 0 0 auto
      }
    }
  }

  .fui-field {
    min-width: 250px; // Force max of 2 fields to a row
  }

  // Formie errors

  .fui-alert {
    padding: 2.4rem 3.2rem;
  }

  .fui-field.fui-error {
    padding-left: 2.4rem;
  }

  // "Narrow" fields (e.g. name prefix)
  .fui-field.fui-name-prefix {
    max-width: 140px;
    min-width: auto;
  }
}