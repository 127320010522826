@import "variables";

.wak-vc-addon-wak_vc_link_button {
   width: 100%;
   margin: 0 auto;
}
.wak-link-button-wrapper {
   &.position-left {
      text-align: left;
   }
   &.position-center {
      text-align: center;
   }
   &.position-right {
      text-align: right;
   }
}
.wak-link-button {
   display: inline-block;
   margin: 0 auto;
}
// > 1024
// @media only screen and (min-width: map-get($breakpoints, lg)) {
//    .wak-vc-addon-wak_vc_link_button {
//       .row:last-child {
//         margin-bottom: 0;
//       }
//    }
// }