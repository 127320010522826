// There are some styles cascading in here from _vc_tta-panel.scss in the theme
@import "variables";

.wak-vc-addon.wak-vc-addon-wak_vc_image_and_accordion {
  width: 100%;
  max-width: $container__max-width;
  padding: 0 $container__gutter;
  margin: 0 auto;
}

.wak-image-accordion-image {
  display: block;
  width: 100%;
}

.wak-image-accordion-content-wrapper {
  padding: 0 $container__gutter;

  &.position-left {
    margin-top: 40px;
  }

  &.position-right {
    margin-bottom: 40px;
  }

  // make accordion to be full width within the module 
  .vc_tta.vc_general.vc_tta-accordion {
    padding: 0;
    max-width: none;
  }
}

.wak-image-accordion-content-title {
  font-size: $h3__font-size;
  line-height: $h3__line-height;
  margin-bottom: map-get($spacers, 3);
}

// > 768
@media only screen and (min-width: map-get($breakpoints, md)) {
  .wak-image-accordion-content-wrapper {
    &.position-left {
      margin-top: 0;
    }

    &.position-right {
      margin-bottom: 0;
    }
  }
}

// > 1024
@media only screen and (min-width: map-get($breakpoints, lg)) {
  .wak-image-accordion-content-wrapper {
    &.position-left {
      padding-right: 80px;
      padding-left: 100px;
    }

    &.position-right {
      padding-right: 100px;
      padding-left: 80px;
    }
  }
}
