// :focus {
//   outline: none;
// }

.visually-hidden {
  position: absolute;
  left: -9999px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.skip-link:focus-visible {
  position: fixed;
  width: auto;
  height: auto;
  z-index: 101;
  top: 0;
  left: 0;
}