@import "variables";

.wak-vc-addon-wak_vc_staff {
  width: 100%;
  max-width: $container__max-width;
  padding: 0 $container__gutter;
  margin: 0 auto;
}

.wak-staff-title {
  margin-bottom: 24px;
}

.wak-staff-members {
  .col-12 {
    .col-12 {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.wak-staff-members-member {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;

  &:last-child {
    margin-bottom: 0;
  }

  .row{
    width: 100%;
  }
}

.wak-staff-members-member-image-wrapper {
  width: 100%;
  max-width: 170px;
  overflow: hidden;
  margin-bottom: map-get($spacers, 2);

  img {
    display: block;
    width: 100%;
  }
}

.wak-staff-members-member-name {
  font-size: $h4__font-size;
  line-height: $h4__line-height;
  margin: 0;
}

.wak-staff-members-member-job {
  font-size: $h6__font-size;
  line-height: $h6__line-height;
  color: $base__colour;
  margin-bottom: 10px;
}

.wak-staff-members-member-read-bio {
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
  color: $base__colour;
  padding: 0.5em 0;
  background: 0 0;
  width: 100%;
  margin-right: 0;
  
  &:hover {
    color: $dark-green;
    background: 0 0;
  }

  p {
    margin: 0;
  }
}

.wak-staff-memembers-member-bio{
  border-top: 1px solid $base__colour;
  border-bottom: 1px solid $base__colour;
}

.wak-staff-memembers-member-bio-content{
  display: none;
}

.wak-staff-bio-modal {
  background: $dark-green;
  padding: 40px 20px;

  &.row {
    display: flex;
    justify-content: center;
    max-width: 1200px;
  }
}

.wak-staff-bio-modal-image-wrapper {
  max-width: 555px;

  img {
    width: 100%;
    // border-radius: 50%;
  }
}

.wak-staff-bio-modal-content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .wak-staff-bio-modal-content-category {
    margin: 20px 0px;

    span {
      color: $white;
      text-transform: uppercase;
      font-size: 1.7rem;
      letter-spacing: 0.2rem;
    }
  }

  h2 {
    margin: 0px;
    font-size: 4.5rem;
    font-weight: 300;
    color: $base__colour;
  }

  h3 {
    margin-top: 0px;
    font-size: 2rem;
    font-weight: 300;
    color: $base__colour;
  }

  .wak-staff-bio-modal-content-bio p {
    font-size: 1.7rem;

    a {
      color: $white;
      margin-left: 10px;
    }
  }
}

.wak-staff-plus{
  margin-bottom: 0.3em;
  margin-right: 0.5em;
}
.wak-staff-minus{
  display: none;
  margin-bottom: 0.3em;
  margin-right: 0.5em;
}

@media only screen and (min-width: 576px) {
  .wak-staff-members-member {
    &:nth-last-child(2) {
      margin-bottom: 0;
    }
  }
}

@media only screen and (min-width: map-get($breakpoints, md)) {
  .wak-staff-members {
    .col-12 {
      .col-md-8 {
        padding-left: 15px;
      }
    }
  }

  .wak-staff-members-member-image-wrapper {
    margin-bottom: 0;
  }
}

@media only screen and (min-width: 1164px) {
  .wak-staff-title {
    margin-bottom: 36px;
  }

  .wak-staff-members {
    .col-12 {
      .col-md-8 {
        padding-left: 0;
      }
    }
  }
}