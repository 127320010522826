.wak-vc-addon-wak_vc_form {
    width: 100%;
    max-width: 1170px;;
    margin: 0 auto;

    form {
        padding: 0 25px;
        @media screen and (min-width: map-get($breakpoints, lg)) {
          padding: 0 128px;
        }
        @media (min-width: 1167px) {
            padding: 0 200px;
        }
    }
}