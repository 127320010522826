.cookie-banner {
  color: #fff;
  background-color: $black;
  border-top: 1px solid #fff;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
}

.cookie-banner__container {
  max-width: $container__max-width;
  margin: 0 auto;
  padding: 12px 0;
}

.cookie-banner__text {
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;

  p {
    margin-right: 80px;
  }
}

.cookie-banner__buttons {
  display: flex;

  button {
    line-height: 18px;
    border: 1px solid #fff;
    padding: 8px 30px;
  }
}

@media only screen and (min-width: map-get($breakpoints, md)) {
  .cookie-banner__container {
    display: flex;
  }

  .cookie-banner__text {
    p {
      margin-right: 0;
  
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}