@import "variables";

.wak-vc-addon-wak_vc_two_column_images {
  width: 100%;
  max-width: $container__max-width;
  padding: 0 $container__gutter;
  margin: 0 auto;
}

.wak-two-column-images-image-wrapper:not(:last-child) {
  margin-bottom: 30px;
}

.wak-two-column-images-image {
  width: 100%;
  height: auto;
  display: block;
}

@media only screen and (min-width: map-get($breakpoints, md)) {
  .wak-two-column-images-image-wrapper:not(:last-child) {
    margin-bottom: 0;
  }
}
