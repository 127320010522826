@import "variables";

.wak-vc-addon-wak_vc_title_on_blue_curved_banner {
  background-color: $dark-green;
}

.wak-title-blue-curved-banner-title-wrapper {
  width: 100%;
  max-width: $container__max-width;
  padding: 0 $container__gutter;
  margin: 0 auto;
  padding-bottom: 7rem;
  padding-top: 7rem;
    &.top {
      padding-top: 1rem;
    }
    &.bottom {
      padding-bottom: 0;
    }
    .wak-character-image {
      margin-bottom: 1px;
      margin-top: -1px;
    }
}

.wak-title-blue-curved-banner-breadcrumbs,
.wak-title-blue-curved-banner-breadcrumbs-mobile {
  background-color: $white;
}

.wak-title-blue-curved-banner-breadcrumbs {
  display: none;
}

.wak-title-blue-curved-banner-breadcrumbs-list {
  width: 100%;
  max-width: $container__max-width;
  padding: 20px $container__gutter;
  margin: 0 auto;
  display: flex;
}

.wak-title-blue-curved-banner-breadcrumbs-list-item:not(:last-of-type) > a::after {
  content: " / ";
}

.wak-title-blue-curved-banner-breadcrumbs-list-item-link {
  font-size: 1.5rem;
  line-height: 1.8rem;
  color: inherit;
}

// > 768

@media only screen and (min-width: map-get($breakpoints, md)) {
  .wak-title-blue-curved-banner-breadcrumbs {
    display: block;
  }

  .wak-title-blue-curved-banner-breadcrumbs-mobile {
    display: none;
  }
}

// event template
.page-template-page-special-events-dark,
.page-template-page-special-events {
  .wak-vc-addon-wak_vc_title_on_blue_curved_banner {
    background-color: $black;
  }
}