@import "variables";

.wak-vc-addon-wak_vc_quote_bubble {
    width: 100%;
    max-width: 1200px;
    padding: 0 15px;
    margin: 0 auto;

    .wak-character-image-quote-bottom{
    	margin-top: -30px;
		margin-bottom: -6px;
    	margin-left: 7%;
	}

	.wak-character-image-quote-top{
    	margin-left: auto;
		margin-right: 7%;
	}
}

.wak-quote-bubble{
	padding: 30px;
	position: relative;

	// &:after{
	// 	position: absolute;
	// 	bottom: -40px;
	// 	left: 0;
	// 	content: "";
	// 	width: 0;
	// 	height: 0;
	// 	border-style: solid;
	// 	border-width: 40px 29px 0 0;
	// 	border-color: #007bff transparent transparent transparent;
	// }


	&.bg-green{
		background-color: $light-green;
	}
	&.bg-magenta{
		background-color: $magenta;
		p, .wak-quote-author{
			color: #fff;
		}
	}
	&.bg-orange{
		background-color: $yellow;
	}
	p{
		margin-bottom: 0.5em;
	}
}

.wak-triangle-down{
	position: relative;

	&:after{
		position: absolute;
		bottom: -40px;
		left: 0;
		content: "";
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 40px 29px 0 0;
		border-color: $light-green transparent transparent transparent;
	}

	&.bg-magenta{
		&:after{
			border-color: $magenta transparent transparent transparent;
		}
	}
	&.bg-orange{
		&:after{
			border-color: $yellow transparent transparent transparent;
		}
	}
}

// event template
.page-template-page-special-events-dark, 
.page-template-page-special-events {
	.wak-vc-addon-wak_vc_quote_bubble {
		.justify-content-center {
      &.quote-align-left {
        justify-content: flex-start !important;
      }

      &.quote-align-right {
        justify-content: flex-end !important;
      }
		}
	}

	.wak-quote-bubble {
		margin-bottom: 41px;

		&:before,
		&:after {
			content: "";
			display: block;
			position: absolute;
			width: 0;
			height: 0;
			overflow: hidden;
			border-style: solid;
			border-color: transparent;
		}
		&:before {
			bottom: -41px;
			left: -6px;
			z-index: 1;
			border-width: 41px 34px 0 0;
		}
		&:after {
			top: auto;
			bottom: -26px;
			left: 0;
			z-index: 2;
			border-width: 26px 20px 0 0;
		}

		&.bg-green {
      border: 6px solid $dark-green;
			background-color: transparent;

			&:before {
				border-top-color: $dark-green;
			}
			&:after {
				border-top-color: $white;
			}
		}

		&.bg-orange {
      border: 6px solid $gold;
			background-color: $gold;

			&:before {
				border-top-color: $gold;
			}
			&:after {
				border-top-color: $gold;
			}
		}
	}
}

.page-template-page-special-events-dark {
	.wak-quote-bubble {
		&.bg-green {
			&:after {
				border-top-color: $black;
			}
		}

		&.bg-orange {
			color: $black;
		}
	}
}