.wak-page-back-button-wrapper {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 25px;

  .wak-page-back-button {
    -webkit-appearance: none;
    background: transparent;
    border: 0 none;
    padding: 15px 0;
    cursor: pointer;
    color: #2e348b;
    font-family: "DINNextRoundedLTPro", serif;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 28px;

    i.fas {
      margin-right: 15px;
    }
  }
}
