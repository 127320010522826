@font-face {
  font-family: "CoreSansG";
  src: url("../fonts/CoreSansG45Regular/font.woff2") format("woff2"),
    url("../fonts/CoreSansG45Regular/font.woff") format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
  font-family: "CoreSansG";
  src: url("../fonts/CoreSansG75ExtraBold/font.woff2") format("woff2"),
    url("../fonts/CoreSansG75ExtraBold/font.woff") format("woff");
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
  font-family: "CoreSansG";
  src: url("../fonts/CoreSansG85Heavy/font.woff2") format("woff2"),
    url("../fonts/CoreSansG85Heavy/font.woff") format("woff");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
  font-family: "CoreSansG";
  src: url("../fonts/CoreSansG35Light/font.woff2") format("woff2"),
    url("../fonts/CoreSansG35Light/font.woff") format("woff");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
  font-family: "CoreSansG";
  src: url("../fonts/CoreSansG65Bold/font.woff2") format("woff2"),
    url("../fonts/CoreSansG65Bold/font.woff") format("woff");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
