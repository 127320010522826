.annual-reports .wpb_wrapper {
  padding: 0;
}

.wak-vc-addon-wak_vc_reports_links {
  width: 100%;
  max-width: $container__max-width;
  padding: 0 $container__gutter;
  margin: 0 auto 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .button {
    margin: 8px;
  }
}