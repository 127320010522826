@import "variables";

.wak-vc-addon-wak_vc_download_logos {
  width: 100%;
  max-width: $container__max-width;
  padding: 0 $container__gutter;
  margin: 0 auto;
  text-align: center;
}

.wak-download-logos-title {
  color: $base__colour;
}

.wak-download-logos-download-all-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.wak-download-logos-logo-wrapper {
  margin: 15px 0;
}

.wak-download-logos-logo-title {
  font-size: 1.7rem;
  line-height: 2.4rem;
  font-weight: 400;
  color: $base__colour;
  margin-bottom: 10px;
}

.wak-download-logos-logo-image-wrapper {
  margin-bottom: 20px;
  border: 1px solid rgba($black, .2);
  padding: 15px;
}

.wak-download-logos-logo-image {
  display: block;
  width: 100%;
}

.wak-download-logos-logo-download-link {
  display: flex;
  justify-content: center;
  color: $base__colour;
  font-weight: 700;
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: $link__text-underline-offset;
}