.wpb_wrapper .wpb_raw_html {
  margin: 0px;
  padding: 0px 25px;
}

@media (min-width: map-get($breakpoints, md)) {
  .wpb_wrapper .wpb_raw_html {
    max-width: 600px;
    margin: 0 auto;
    padding: 50px 0px 30px 0px;
  }
}

@media (min-width: map-get($breakpoints, lg)) {
  .wpb_wrapper .wpb_raw_html {
    max-width: 800px;
  }
}

@media only screen and (min-width: 1200px) {
  .wpb_wrapper .wpb_raw_html {
    padding: 0px 125px;
    max-width: 1200px;
  }
}

@media only screen and (min-width: 1400px) {
  .wpb_wrapper .wpb_raw_html {
    padding: 0px 230px;
    max-width: 1400px;
  }
}