.wpb_single_image,
.wpb_video_wrapper {
  width: 100%;
  max-width: $container__max-width;
  padding: 0 $container__gutter;
  margin: 0 auto;
  text-align: center;
}

.wpb_wrapper.vc_figure .vc_single_image-wrapper img {
  display: block;
  width: 100%;
  height: auto;
}

// TODO: Refactor this
.wpb_video_widget.vc_video-aspect-ratio-169 {
  .wpb_wrapper .wpb_video_wrapper {
    max-width: 1170px;
    margin: 0 25px !important;
    width: calc(100vw - 50px);

    @media only screen and (min-width: 1200px) {
      width: 100%;
      padding-top: 675px;
      margin: 0 auto !important;
    }
  }
}
