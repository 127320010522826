html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

ul {
  padding: 0px;
}

img, svg {
  height: auto;
  max-width: 100%;
}

.clear:after,
.clear:before {
  content: "";
  display: table;
  clear: both;
  float: none;
}

.padding-0 {
  padding-right: 0;
  padding-left: 0;
}

.margin-0 {
  margin-right: 0;
  margin-left: 0;
}

admin-bar {
  --admin-bar-font-size: 1.4rem;
  &.fixed {
    z-index: var(--admin-bar-z-index, 100000);
  }
}
