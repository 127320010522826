@import "variables";

.page-box-row {
  width: 100%;
  max-width: $container__max-width;
  padding: 0; // Inner gutter taken care of by children
  margin: 0 auto;

  &.first-child,
  &:first-child {
    padding-top: 20px;
  }

  &.last-child,
  &:last-child {
    padding-bottom: 30px;
  }

  .wpb_wrapper {
    padding-bottom: 0;
  }
}

.wak-vc-addon-wak_vc_page_box {
  margin-bottom: 20px;

  & > .row {
    position: relative;
    margin-right: 0;
    margin-left: 0;
  }
}

.wak-page-box-image-wrapper {
  padding-top: 50%;
}

.wak-page-box-image {
  position: absolute;
  display: block;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.wak-page-box-content-wrapper {
  border: solid 6px $base__colour;
  padding: 1em;

  &:before,
  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    border-style: solid;
    border-color: transparent;
  }
  &:before {
    top: -41px;
    right: -6px;
    z-index: 1;
    border-bottom-color: $base__colour;
    border-width: 0 0 41px 34px;
  }
  &:after {
    top: -26px;
    right: 0;
    z-index: 2;
    border-bottom-color: $white;
    border-width: 0 0 26px 20px;
  }
}

.wak-page-box-content-content {
  p:last-child {
    margin-bottom: 0;
  }
}

.wak-page-box-content-title {
  color: $base__colour;
  font-size: 2.2rem;
  line-height: 2.8rem;
}

.wak-page-box-link{
  &:hover{
    h3 {
      color: $dark-green;
    }
  }
}

.page-box-on-bg {
  &--image {
    padding: 0 0 16px;

    img {
      display: block;
      width: 100%;
    }
  }

  &--content {
    padding: 0;
  }

  .wak-page-box-link:hover {
    color: $grey;

    h3 {
      color: $grey;
    }
  }
}

.page-boxes-on {
  margin: -20px 0;
  padding: 60px 0px 40px;

  &--green {
    background-color: $light-green;
  }

  &--orange {
    background-color: $yellow;
  }

  &--magenta {
    background-color: $magenta;
    color: $white;

    .wak-page-box-link {
      color: $white;

      h3 {
        color: $white;
      }

      &:hover {
        color: $white;

        h3 {
          color: $white;
        }
      }
    }
  }

  &--with-character {
    padding-top: 100px;
  }

  .wak-character-image {
    position: absolute;
    top: -70px;
    left: 16px;
  }

  .row {
    position: relative;
    margin-bottom: -20px;
  }
}

@media only screen and (min-width: 576px) {
  .wak-vc-addon-wak_vc_page_box {
    & > .row {
      min-height: 164px;
    }
  }

  .wak-page-box-image-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    padding-top: 0;
  }

  .wak-page-box-content-wrapper {
    margin-left: 50%;

    &:before,
    &:after {
      display: none;
    }
  }
}

// > 768
@media only screen and (min-width: map-get($breakpoints, md)) {
  .wak-page-box-image-wrapper {
    position: relative;
    padding: 0;
    height: 256px;
  }

  .wak-page-box-image {
    position: relative;
  }

  .wak-page-box-content-wrapper {
    position: relative;
    margin-left: 0;
    height: calc(100% - 256px);

    &:before,
    &:after {
      display: block;
    }
  }

  .page-box-row {
    .vc_column-inner {
      display: flex;
      height: 100%;

      .wpb_wrapper {
        display: flex;
        flex-wrap: wrap;
        padding: 30px 0 0;

        .wak-vc-addon-wak_vc_page_box {
          display: flex;
          flex: 0 0 100%;
          margin-bottom: 30px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

// > 1024
@media only screen and (min-width: map-get($breakpoints, lg)) {
  .page-boxs-on {
    margin: -30px 0;
    padding: 50px 0px;
  }
}

// event template
.page-template-page-special-events-dark, 
.page-template-page-special-events {
  .wak-page-box-content-wrapper {
    border: none;
    background-color: $black;
    padding: 22px 1em;

    &:before, &:after {
      content: none;
    }
  }

  .wak-page-box-content-title {
    color: $gold;
    font-weight: 300;
    text-transform: uppercase;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .wak-page-box-link:hover {
    .wak-page-box-content-wrapper {
      background-color: $dark-green;
    }

    h3 {
      color: $gold;
    }
  }
}