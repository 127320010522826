* {
  font-family: "CoreSansG", sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

html {
  font-size: 62.5%; /* font REM reset 10px */
}

body {
  font-family: "CoreSansG", sans-serif;
  font-size: 17px;
  font-size: $base__font-size;
  line-height: $base__line-height;
  margin: 0;
  padding: 0;
  font-feature-settings: "pnum" on, "lnum" on;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.4em;
}

h1,
.h1 {
  color: $h1__colour;
  font-weight: $h1__font-weight;
  font-size: $h1__font-size;
  line-height: $h1__line-height;

  &.h1--small {
    font-size: $h1--small__font-size;
    line-height: $h1--small__line-height;
  }
}

h2,
.h2 {
  color: $h2__colour;
  font-weight: $h2__font-weight;
  font-size: $h2__font-size;
  line-height: $h2__line-height;
}

h3,
.h3 {
  color: $h3__colour;
  font-weight: $h3__font-weight;
  font-size: $h3__font-size;
  line-height: $h3__line-height;
}

h4,
.h4 {
  color: $h4__colour;
  font-weight: $h4__font-weight;
  font-size: $h4__font-size;
  line-height: $h4__line-height;
}

h5,
.h5 {
  color: $h5__colour;
  font-weight: $h5__font-weight;
  font-size: $h5__font-size;
  line-height: $h5__line-height;
}

h6,
.h6 {
  color: $h6__colour;
  font-weight: $h6__font-weight;
  font-size: $h6__font-size;
  line-height: $h6__line-height;
}

p {
  margin-top: 0;
}

p a {
  text-decoration: $link__text-decoration;
}

a {
  color: $base__colour;
  text-decoration: none;
  text-decoration-thickness: $link__text-decoration-thickness;
  text-underline-offset: $link__text-underline-offset;

  &:hover {
    color: $dark-green;
  }
}

// .text-color-yellow {
//   color: $yellow;
// }

// .text-color-blue {
//   color: $light-blue;
// }

// .text-color-blue-navy {
//   color: $blue;
// }

.block-title {
  font-size: $h1--small__font-size;
  line-height: $h1--small__line-height;
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: -0.02em;
  margin: 0;
  padding: 0;

  > span {
    display: inline;
    margin: 0;
    padding: 0 8px;
    color: $white;
    box-decoration-break: clone;
    font-size: inherit;
    background-image: linear-gradient(transparent 0 6px, $black 5px 63px, transparent 63px);
  }

  &.text-black {
    > span {
      color: $black;
      background-image: linear-gradient(transparent 0 6px, $white 5px 63px, transparent 63px);
    }
  }
}

blockquote {
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: $h5__font-size;
  line-height: $h5__line-height;

  *:empty {
    display: none;
  }

  > *:last-child {
    margin-bottom: 0;
  }
}

ul,
ol {
  list-style: none;
}

.wpb_text_column {
  ul {
    margin-left: 20px;
    li {
      padding: 0 0 0 5px;
      margin-bottom: 4px;
      position: relative;
      &::before {
        content: "\2022";
        color: $dark-green;
        display: block;
        position: absolute;
        left: -21px;
        font-size: 20px;
      }
    }
  }

  ol {
    counter-reset: my-counter;
    padding-left: 20px;
    li {
      counter-increment: my-counter;
      padding: 0 0 0 5px;
      margin-bottom: 4px;
      position: relative;
      &::before {
        content: counter(my-counter);
        color: $dark-green;
        display: block;
        position: absolute;
        left: -21px;
        font-size: 20px;
      }
    }
  }

  blockquote {
    padding-top: 56px;
    margin-top: 15px;
    margin-bottom: 15px;
    background-image: url(../img/quotation-marks.svg);
    background-repeat: no-repeat;
    background-position: left 2px top 0px;
    background-size: 73px auto;
  }

  *:last-child {
    margin-bottom: 0;
  }
}

//   .wpb_wrapper {
//     p {
//       max-width: 767px;
//       color: $grey;
//       font-family: "CoreSansG", sans-serif;
//       font-size: 17px;
//       letter-spacing: 0;
//       line-height: 24px;
//     }
//   }

.hidden-label {
  visibility: hidden;
  display: block;
  width: 0px;
  height: 0;
}

// > 768

@media only screen and (min-width: map-get($breakpoints, md)) {
  .wpb_text_column .wpb_wrapper {
    blockquote {
      padding-top: 0;
      padding-left: 100px;
      margin-top: 20px;
      margin-bottom: 20px;
      background-position: left 2px top 6px;
    }
  }
}

// event template
.page-template-page-special-events-dark {
  color: $white;
  background-color: $black;

  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6 {
    color: $white;
  }
  
  a {
    color: $white;
  
    &:hover {
      color: $dark-green;
    }
  }
}