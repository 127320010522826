%btn {
  background-color: $black;
  color: $white;
  border-radius: 0;
  -webkit-appearance: none;
  margin-right: 15px;
  display: flex;
  width: fit-content;
  text-transform: initial;
  letter-spacing: 0;
  font-size: 1.7rem;
  line-height: 2.4rem;
  padding: 1rem 3rem;
  text-align: center;
  border: 0;

  &:hover {
    cursor: pointer;
    background-color: lighten($black, 10%);
    color: $white;
  }
}

.button, button {
  @extend %btn;

  &.bg-color-yellow {
    color: $black;
    background-color: $yellow;

    &:hover {
      color: $black;
      background-color: darken($yellow, 10%);
    }
  }

  &.button--no-margin {
    margin-right: 0;
  }

  &.bg-color-transparent {
    background-color: transparent;
    border: 1px solid $white;
    color: $white;

    &:hover {
      color: $white;
    }
  }

  &.bg-color-green {
    background-color: $light-green;
    color: $black;

    &:hover {
      background-color: darken($light-green, 10%);
      color: $black;
    }
  }

  &.bg-color-yellow {
    color: $black;
    background-color: $yellow;

    &:hover {
      color: $black;
      background-color: lighten($yellow, 10%);
    }
  }

  &.bg-color-white, &.text-black {
    color: $black;
    background-color: $white;

    &:hover {
      color: $black;
      background-color: darken($white, 10%);
    }
  }
}

// event template
.page-template-page-special-events-dark, 
.page-template-page-special-events {
  .button {
    color: $black;
    background-color: $gold;

    &:hover {
      background-color: $dark-green;
    }
  }
}

.page-template-page-special-events-dark {
  .button {
    &:hover {
      color: $black;
    }
  }
}