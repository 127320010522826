@import "variables";

.wak-vc-addon-wak_vc_event_block {
	background: $light-green;
	// width: 100%;
	// max-width: $container__max-width - ($container__gutter * 2);
	margin: 0 auto;
	padding: 24px 20px 20px;
	color: $black;
	&.bg-magenta{
		background-color: $magenta;
		color: $white;
	}
	&.bg-yellow{
		background-color: $yellow;
	}
}

.wak-event-info{
	font-weight: 700;
	// display: inline-block;
	// width: 49%;
	margin-bottom: 0.5em;
	display: flex;
	svg{
		margin-right: 0.3em;
	}
}

.wak-event-entry{
	font-weight: 700;
	margin-bottom: 0.5em;
}

.wak-event-block-button{
	margin-top: 1em;
}

// > 768
@media only screen and (min-width: map-get($breakpoints, md)) {
	.wak-vc-addon-wak_vc_event_block {
		width: calc(100% - #{$container__gutter * 2});
    max-width: $container__max-width - ($container__gutter * 2);
	}
	.wak-event-info{
		width: auto;
		margin-right: 1.5em;
		display: inline-flex;
	}
	.wak-event-entry{
		display: inline-block;
		margin-right: 1.5em;
		margin-top: 0.5em;
	}
}

@media only screen and (min-width: map-get($breakpoints, lg)) {
	.wak-event-block-button{
		margin-top: 1.2em;
	}
}