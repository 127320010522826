@import "variables";

.wak-vc-addon-wak_vc_gallery_grid {
  width: 100%;
  max-width: $container__max-width;
  padding: 0 $container__gutter;
  margin: -15px auto;
}

.wak-gallery-grid-wrapper {
  margin: 15px 0;
}

.wak-gallery-grid-thumbnail {
  display: block;
  width: 100%;
}