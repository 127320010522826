.stat-box {
    margin-left: auto;
    margin-right: auto;
    width: 100%;

    // > 768
    @media only screen and (min-width: map-get($breakpoints, md)) {
        &:not(.full-width) {
            max-width: 370px;
        }
    }
    
    .stat-box-inner {
        padding: 3rem;
        text-align: center;
    
        h2 {
            font-size: 8.5rem;
            line-height: 1;
            margin: 0;
            font-weight: 900;
            letter-spacing: -0.3rem;
        }

        p {
            margin: 0;
        }

        &.bg-magenta {
            color: $white;
            h2 {color: $white;}
        }


        .stat-box-title {
            margin-bottom: 1.6rem;
        }

        &.stats_box {
            text-transform: uppercase;
            min-height: 190px;

            h2 {
                font-size: 4.2rem;
            }
        }

    }

    .wak-character-image {
        margin-left: 40px;
        margin-top: -25px;
        position: relative;
    }

}

