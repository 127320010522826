@import "variables";

.wak-vc-addon-wak_vc_quote {
  width: 100%;
  max-width: $container__max-width;
  padding: 0 $container__gutter;
  margin: 0 auto;

  .justify-content-center {
    &.quote-align-left {
      justify-content: flex-start !important;
    }

    &.quote-align-right {
      justify-content: flex-end !important;
    }
  }
}

.quote-text {
  font-weight: 400;
}

.wak-quote-author {
  margin-top: 7px;
}

@media (min-width: map-get($breakpoints, md)) {
  .wak-vc-addon-wak_vc_quote {
    .justify-content-center {
      &.quote-align-left {
        margin-left: 90px;
      }
    }
  }

  .wak-quote-content {
    position: relative;

    .quotation-marks {
      position: absolute;
      top: 8px;
      left: 0;
      transform: translateX(calc(-100% - 30px));
    }
  }

  .wak-quote-author {
    margin-top: 24px;
    font-size: $h5__font-size;
    line-height: $h5__line-height;
    font-weight: 700;
  }
}

// event template
.page-template-page-special-events-dark,
.page-template-page-special-events {
  .wak-vc-addon-wak_vc_quote {
    margin-bottom: 41px;

    .justify-content-center {
      &.quote-align-left,
      &.quote-align-right {
        margin-left: 0;
        margin-right: 0;
      }

      .col {
        padding: 0;
      }
    }
  }

  @media (min-width: map-get($breakpoints, md)) {
    .wak-quote-content {
      .quotation-marks {
        position: static;
        transform: none;
      }
    }
  }
}