@import "variables";

.wak-vc-addon-wak_vc_sponsors_support {
  width: 100%;
  max-width: 1000px;
  padding: 0 $container__gutter;
  margin: 0 auto;
  text-align: center;
}

.wak-sponsors-support-title {
  color: $base__colour;
}

.wak-sponsors-support-image {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 20px;
  margin: auto;
  object-fit: contain;
  // max-width: calc(100% - 40px);
  max-height: calc(100% - 40px);
  transform: translate(-50%, -50%);
}

.wak-sponsors-support-images-inner{
  border: solid 1px $black;
  padding-top: 100%;
  position: relative;
}

.wak-sponsors-support-images-wrapper{
  margin-top: 1em;
  margin-bottom: 1em;
}

// @media only screen and (min-width: map-get($breakpoints, md)) {
//   .wak-sponsors-support-images-inner{
//     height: 220px;
//   }
// }

// event template
.page-template-page-special-events-dark, 
.page-template-page-special-events {
  .wak-vc-addon-wak_vc_sponsors_support {
    max-width: 1200px;
    text-align: left;
  }

  .wak-sponsors-support-title {
    color: $gold;
    font-weight: 300;
    font-size: $h3__font-size;
    line-height: $h3__line-height;
  }

  .wak-sponsors-support-images {
    justify-content: flex-start !important;
  }

  .wak-sponsors-support-images-inner {
    border: none;
  }

  .wak-sponsors-support-image {
    padding: 0;
  }
}