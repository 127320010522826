@import "variables";

.stats-box-wrapper {
  width: 100%;
  max-width: $container__max-width;
  padding: 0;
  margin: 20px auto;
  display: flex;
  justify-content: center;

  // > 1024
  @media only screen and (min-width: map-get($breakpoints, lg)) {
    margin: 30px auto;
  }

  .wpb_column {
    margin: 5px 0;
  }

  .vc_column-inner {
    padding: 0;
  }

  .wpb_wrapper {
    padding: 0;
    padding: 0 $container__gutter;
  }
}
