.content-columns {
  text-align: center;
}

.content-column {
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }

  h3 {
    margin-bottom: 4px;
  }

  p {
    font-weight: 700;
  }

  .button {
    margin: 0 auto;
  }
}

.content-column-image {
  display: block;
  width: 160px;
  height: auto;
  margin: 0 auto 16px;
}

// > 768
@media only screen and (min-width: map-get($breakpoints, md)) {
  .content-column {
    margin-bottom: 0;
  }

  .content-column-image {
    width: 200px;
  }
}