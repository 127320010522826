//---------//
// Colours //
//---------//

$white:                 #FFFFFF;
$grey:                  #444444;
$blue:                  #2E348B;
$light-blue:            #1089CA;
$grey-blue:             #9699C5;
$lightest-blue:         #EDF7FD;
$pending-blue:          #F1F9FD;
$pending-blue-border:   #DCEFFC;
$pending-grey:          #4E5E7D;
$light-purple:          #8285B9;
$footer-hr-blue:        #424896;
$background-light-blue: #D3EBFB;
$yellow:                #FFC800;
$form-border-blue:      #C0C2DC;

// Introduced for 2021 rebrand
$black: #1D1D1D;
$dark-green: #009F4D;
$mid-green: #4CAE04;
$light-green: #A4D233;
$magenta: #DA1984;
$orange: #E35205;
$yellow: #FFA400;
$light-grey: rgba(0, 0, 0, 0.05);

// Introduced for event template (02/2023)
$gold: #CDA864;

//--------//
// Layout //
//--------//

$container__max-width: 1200px;
$container__gutter: 15px;

$spacers: (
  1: 10px,
  2: 20px,
  3: 30px,
  4: 60px,
  5: 100px
);

$breakpoints: (
  md: 768px,
  lg: 1024px
);

//------------//
// Typography //
//------------//

$base__colour: $black;
$base__font-size: 1.7rem;
$base__line-height: 2.4rem;
$base--large__font-size: 2rem;
$base--large__line-height: 2.8rem;

$h1__colour: $base__colour;
$h1__font-weight: 700;
$h1__font-size: 6rem;
$h1__line-height: 6.8rem;
$h1--small__font-size: 5.6rem;
$h1--small__line-height: 5.6rem;

$h2__colour: $base__colour;
$h2__font-weight: 700;
$h2__font-size: 4rem;
$h2__line-height: 4.8rem;

$h3__colour: $base__colour;
$h3__font-weight: 700;
$h3__font-size: 3.4rem;
$h3__line-height: 4rem;

$h4__colour: $base__colour;
$h4__font-weight: 700;
$h4__font-size: 2.6rem;
$h4__line-height: 3.2rem;

$h5__colour: $base__colour;
$h5__font-weight: 700;
$h5__font-size: 2.2rem;
$h5__line-height: 2.8rem;

$h6__colour: $base__colour;
$h6__font-weight: 700;
$h6__font-size: 1.7rem;
$h6__line-height: 2.4rem;

$field-input__font-size: 1.5rem;
$field-input__line-height: 1.8rem;

$field-label__font-size: 1.5rem;
$field-label__line-height: 1.8rem;
$field-label__line-height--base: 2.1rem;
$field-label__font-weight: 700;
$field-label__font-weight--base: 400;

$link__text-decoration: underline;
$link__text-decoration-thickness: 1px;
$link__text-underline-offset: 3px;