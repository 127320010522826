@import "variables";

.wak-vc-addon-wak_vc_image_and_copy_blue_square_bg {
  width: 100%;
  max-width: $container__max-width;
  padding: 0 $container__gutter;
  margin: 0 auto;

  .wak-character-image{
    position: absolute;
    display: block;
    max-width: 70px;
    left: auto;
    right: 110px;
  }

  .wak-character-image-left{
    left: 110px;
    right: auto;
  }
}

.wak-image-copy-bg-image {
  display: block;
  width: calc(100% - 15%);
  margin: -50px 15% 0;
  position: relative; // Give stacking context
}


.wak-image-copy-bg-image-wrapper {
  position: relative;
  border-top: 50px solid $white;
  padding-bottom: 70px;
  margin-bottom: 30px;

  &.bg-green{
    background-color: $mid-green;
  }
  &.bg-magenta{
    background-color: $magenta;
  }
  &.bg-orange{
    background-color: $orange;
  }
  &.bg-yellow{
    background-color: $yellow;
  }

  &:before {
    content: "";
    width: 50px;
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100%;
    background-color: $white;
  }

  &.left-shadow {
    .wak-image-copy-bg-image {
      margin-right: 0;
    }

    &:before {
      right: 0;
    }
  }

  &.right-shadow {
    .wak-image-copy-bg-image {
      margin-left: 0;
    }

    &:before {
      left: 0;
    }
  }
}

.wak-image-copy-bg-content-button-wrapper {
  margin-top: map-get($spacers, 3);
}


// > 768

@media only screen and (min-width: map-get($breakpoints, md)) {
  .wak-image-copy-bg-image-wrapper {
    margin-top: .7rem;
    margin-bottom: 0;
  }
}

// > 1024

@media only screen and (min-width: map-get($breakpoints, lg)) {
  .wak-vc-addon-wak_vc_image_and_copy_blue_square_bg {
    .row:last-child {
      margin-bottom: 0;
    }
  }

  .wak-image-copy-bg-content-wrapper {
    &.position-left {
      padding-right: 15px;
      padding-left: 100px;
    }

    &.position-right {
      margin-top: 0;
      padding-right: 100px;
      padding-left: 15px;
    }
  }

  .wak-image-copy-bg-image {
    width: calc(100% - 90px);
    margin: -84px 90px 0;
  }

  .wak-image-copy-bg-image-wrapper {
    border-top-width: 84px;

    &:before {
      width: 84px;
    }
  }
}