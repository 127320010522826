@import "variables";

.wak-vc-addon-wak_vc_donate {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  background-color: $yellow;

  &--yellow {
    background-color: $yellow;
  }
  &--green {
    background-color: $light-green;
  }
  &--orange {
    background-color: $orange;
  }
  &--magenta {
    background-color: $magenta;
  }
  &--orange, &--magenta {    
    color: $white;
    .wak-donate-content-title {
      color: $white;
    }
  }
}

.wak-donate-image-wrapper {
  overflow: hidden;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.wak-donate-content-wrapper {
  padding: $container__gutter;
}

.wak-donate-content-title {
  color: $base__colour;
  margin-bottom: map-get($spacers, 2);
}

.wak-donate-content-content {
  margin-bottom: map-get($spacers, 3);
}

.wak-donate-content-frequencies-wrapper {
  margin-top: -8px;
}

.wak-donate-content-frequency-amounts-values-wrapper {
  margin-bottom: -8px + 10px;
}

.wak-donate-content-frequencies-wrapper,
.wak-donate-content-frequency-amounts-values-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-right: -8px;
  margin-left: -8px;
}

.wak-donate-content-frequency-wrapper,
.wak-donate-content-frequency-amounts-amount-value + label {
  display: flex;
  align-items: center;
  border: 1px solid $black;
  background-color: $white;
  color: $base__colour;
  padding: 13px 15px;
  cursor: pointer;
  margin: 8px;
  flex: 1;
  flex-grow: 1;
  flex-shrink: 0;

  &:hover,
  &.checked,
  &.active {
    background-color: $black;
    color: $white;
  }
}

.wak-donate-content-frequency-amounts-amount-value {
  display: none;

  & + label {
    display: block;
    text-align: center;
    font-size: 1.7rem !important;
    font-weight: inherit !important;
    line-height: 1.15 !important;
  }

  &:checked + label {
    background-color: $black;
    color: $white;
  }
}

.wak-donate-content-frequency-wrapper {
  input {
    cursor: pointer;
  }

  &:hover .form__custom-radio-label:before {
    border-color: $white;
  }
}

.wak-donate-content-amount-wrapper {
  position: relative;
  margin: map-get($spacers, 3) 0;

  input[type="number"] {
    -moz-appearance: textfield;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.wak-donate-content-amount-input-text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 15px;
}

input.wak-donate-content-amount-input {
  width: 100%;
  padding-left: 30px !important; // important cascade fail
}

fieldset {
  border: 0; // override Firefox style
  padding: 0; // override Firefox style
}

.form__custom-radio-input {
  opacity: 0;
  outline: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1.25rem;
  margin: 0;
}

.form__custom-radio-label {
  position: relative;
  display: flex;
  align-items: center;
  &:before {
    content: "";
    background-color: transparent;
    border: 1px solid $black;
    border-radius: 100%;
    height: 1.6rem;
    width: 1.6rem;
    transition: background-color 0.2s;
    .wak-donate-content-frequency-wrapper.checked & {
      background-color: $black;
      border-color: $white;
    }
  }
  &:after {
    content: "";
    background-color: $white;
    width: 1rem;
    height: 1rem;
    border-radius: 100%;
    position: absolute;
    left: 3px;
    opacity: 0;
    transition: all 0.2s;
    .wak-donate-content-frequency-wrapper.checked & {
      background-color: #fff;
      opacity: 1;
      transform: scale(1);
    }
  }
  p {
    margin: 0 0 0 10px;
  }
}

// > 768
@media only screen and (min-width: map-get($breakpoints, md)) {
  .wak-vc-addon-wak_vc_donate {
    width: calc(100% - #{$container__gutter * 2});
    max-width: $container__max-width - ($container__gutter * 2);
    flex-direction: row;
  }

  .wak-donate-content-wrapper {
    padding: 50px;
  }
}

// > 1024
@media only screen and (min-width: 1024px) {
  .wak-donate-content-wrapper {
    padding: 70px 100px;
  }
}
