#footer {
  padding: 60px 0 30px;
  background-color: $black;
  color: $white;

  a {
    color: inherit;
  }
}

#footer_row_1_sidebar,
#footer_row_2_sidebar {
  width: 100%;
  max-width: $container__max-width;
  padding: 0;
  margin: 0 auto;
}

#footer_row_1_sidebar {
  @extend .row;

  .widget-title {
    color: inherit;
    font-size: $h5__font-size;
    line-height: $h5__line-height;
    margin-bottom: 16px;
  }

  ul {
    margin: 0;
  }

  li {
    margin-bottom: 4px;
  }

  a {
    font-size: 1.5rem;
    line-height: 1.8rem;
  }

  .widget_nav_menu {
    @extend .col-sm-4;
    @extend .col-lg-2;
    margin-bottom: 30px;
  }
}

// Starlight logo
#media_image-2 {
  display: block;
  width: 225px;
  @extend .col-12;
  @extend .col-lg-4;

  img {
    width: 100%;
    max-width: 240px !important;
    margin-bottom: 30px;
  }
}

// Fundraiser logo
#media_image-3 {
  @extend .col-12;
  display: flex;
  justify-content: flex-end;
  //margin-top: 30px;

  img {
    display: block;
    width: 162px;
    margin-bottom: 15px;
  }
}

#footer_row_2_sidebar {
  display: flex;
  flex-direction: column;
  font-size: 1.2rem;
  line-height: 1.4rem;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    height: 1px;
    top: 0;
    left: 15px;
    right: 15px;
    background-color: $white;
  }
}

//  Privacy policy menu
#nav_menu-6 {
  padding: 15px;

  .menu {
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    li:not(:first-child) {
      margin-left: 15px;
    }
  }
}

// Copyright
#text-2 {
  padding: 15px;
  text-align: center;

  .textwidget {
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: $base__line-height;
  }

  p {
    margin: 0;
  }
}

// Social icons
#menu-social-menu {
  padding: 15px;
  display: flex;
  justify-content: center;
  font-size: 0;

  li {
    margin: 0 7px;
  }

  a {
    display: block;
    width: 25px;
    height: 25px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  .facebook a {
    background-image: url("../img/facebook.svg");
  }

  .twitter a {
    background-image: url("../img/twitter.svg");
  }

  .instagram a {
    background-image: url("../img/instagram.svg");
  }

  .linkedin a {
    background-image: url("../img/linkedin.svg");
  }

  .youtube a {
    background-image: url("../img/youtube.svg");
  }
}

@media (min-width: 576px) {
  #footer_row_1_sidebar {
    .widget-title {
      margin-bottom: 30px;
    }
  }
}

@media only screen and (min-width: map-get($breakpoints, lg)) {
  #footer_row_2_sidebar {
    flex-direction: row;
    justify-content: space-between;
  }

  // Social and nav
  #nav_menu-5, #nav_menu-6 {
    width: 240px;
    flex-shrink: 0;
  }

  #menu-social-menu {
    justify-content: flex-start;
    li {
      margin: 0;

      &:not(:last-child) {
        margin-right: 15px;
      }
    }
  }

  #nav_menu-6 {
    .menu {
      justify-content: flex-end;
    }

    li {
      margin: 0;

      &:not(:first-child) {
        margin-left: 15px;
      }
    }
  }
}
