.bg-green{
  background-color: $light-green;
}
.bg-magenta{
  background-color: $magenta;
}
.bg-orange{
  background-color: $yellow;
}

.wak-triangle-bottom-left{
	position: relative;

	&:after{
		position: absolute;
		bottom: -40px;
		left: 0;
		content: "";
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 40px 29px 0 0;
		border-color: $light-green transparent transparent transparent;
	}

	&.bg-magenta{
		&:after{
			border-color: $magenta transparent transparent transparent;
		}
	}
	&.bg-orange{
		&:after{
			border-color: $yellow transparent transparent transparent;
		}
	}
}

.wak-triangle-top-right{
	position: relative;

	&:after{
		position: absolute;
		top: -40px;
		right: 0;
		content: "";
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 0 40px 29px;
		border-color: transparent transparent $light-green transparent;
	}

	&.bg-magenta{
		&:after{
			border-color: transparent transparent $magenta transparent;
		}
	}
	&.bg-orange{
		&:after{
			border-color: transparent transparent $yellow transparent;
		}
	}
}

.lc--nbm {
	& > *:last-child {
		margin-bottom: 0;
	}
}