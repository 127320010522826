@import "variables";

.wak-vc-addon-wak_vc_image_and_stat {
  width: 100%;
  max-width: $container__max-width;
  padding: 0 $container__gutter;
  margin: 0 auto;
}

.image_and_stat__image {
  display: block;
  width: 100%;
}

.image_and_stat__stat-wrapper {
  margin: 30px 0;
}
