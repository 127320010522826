@import "variables";

.wpb_gmaps_widget.wpb_content_element,
.wak-vc-addon-wak_vc_contact {
  width: 100%;
  padding: 0 $container__gutter;
}

.wak-contact-address-wrapper,
.wak-contact-phone-wrapper,
.wak-contact-email-wrapper {
  display: flex;
  margin-bottom: 20px;
}

.wak-contact-address-icon,
.wak-contact-phone-icon,
.wak-contact-email-icon {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 30px;
  margin-right: 15px;
}

// > 768px

@media only screen and (min-width: map-get($breakpoints, md)) {
  .wpb_gmaps_widget.wpb_content_element,
  .wak-vc-addon-wak_vc_contact {
    max-width: calc(#{$container__max-width} / 2);
  }

  .wpb_gmaps_widget.wpb_content_element {
    padding-right: 0;
    float: right;

    &:after {
      content: "";
      display: block;
      clear: both;
      width: 100%;
    }
  }

  .wak-vc-addon-wak_vc_contact {
    padding-left: 0;
  }
}
