@import "variables";

$jumbotron-height__mobile: 500px;
$jumbotron-height__desktop: 570px;
$jumbotron-height__desktop--breadcrumb: 634px;

.wak-jumbotron-content-content > p {
  color: $black;
  display: inline-block;
}

.wak-vc-addon.wak-vc-addon-wak_vc_jumbotron {
  background-color: $dark-green;
  display: flex;
  flex-direction: column;
}

.wak-jumbotron-wrapper {
  width: 100%;
}

.wak-jumbotron-image {
  display: block;
  width: 100%;

  @supports (object-fit: cover) {
    height: 100%;
    object-fit: cover;
  }
}

.clipped-img,
.wak-vc-addon.wak-vc-addon-wak_vc_jumbotron.right .wak-jumbotron,
.wak-vc-addon.wak-vc-addon-wak_vc_jumbotron.left .wak-jumbotron {
  height: $jumbotron-height__mobile;
}

.wak-jumbotron-clipped {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  padding: 40px $container__gutter;
  z-index: 1;
}

.jumbotron-title {
  margin-bottom: map-get($spacers, 3);

  .wak-character-image {
    margin-bottom: 1px;
    margin-top: -1px;
  }
}

.wak-jumbotron-content-wrapper > *:last-child {
  margin-bottom: 0;
}

.wak-jumbotron-content-content {
  p {
    font-size: 2rem;
    line-height: 2.8rem;
  }

  > *:last-child {
    margin-bottom: 0;
  }
}

.wak-jumbotron-content-buttons {
  display: flex;
  margin-top: map-get($spacers, 3);
}

// Left close -> fullwidth
.wak-vc-addon.wak-vc-addon-wak_vc_jumbotron.left-close {
  justify-content: flex-end;
  position: relative;
  height: $jumbotron-height__mobile;

  .wak-jumbotron-clipped {
    height: $jumbotron-height__mobile;
  }

  .wak-jumbotron-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }

  .wak-jumbotron {
    height: $jumbotron-height__mobile;
  }
}

// Breadcrumbs
.wak-jumbotron-breadcrumbs,
.wak-jumbotron-breadcrumbs-mobile {
  background-color: $white;
  width: 100%;
  z-index: 1;
}

.wak-jumbotron-breadcrumbs {
  display: none;
}

.wak-jumbotron-breadcrumbs-list {
  width: 100%;
  max-width: $container__max-width;
  padding: 20px $container__gutter;
  margin: 0 auto;
  display: flex;
}

.wak-jumbotron-breadcrumbs-list-item:not(:last-of-type) > a::after {
  content: " / ";
}

.wak-jumbotron-breadcrumbs-list-item-link {
  font-size: 1.5rem;
  line-height: 1.8rem;
  color: inherit;
}

// Content label
.wak-jumbotron-content-label,
.wak-jumbotron-date-label {
  font-size: 1.2rem;
  line-height: 1.2rem;
  font-weight: 700;
  text-transform: uppercase;
  background-color: $black;
  color: $white;
  padding: 7px 10px;
  letter-spacing: 0.05em;
  display: inline-block;
  margin-bottom: 17px;
}

// > 768

@media only screen and (min-width: map-get($breakpoints, md)) {
  .wak-vc-addon.wak-vc-addon-wak_vc_jumbotron {
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;

    &.left {
      flex-direction: row-reverse;
    }
  }

  .wak-jumbotron-clipped {
    justify-content: center;
  }

  .wak-jumbotron-clipped,
  .clipped-img,
  .wak-vc-addon.wak-vc-addon-wak_vc_jumbotron.right .wak-jumbotron,
  .wak-vc-addon.wak-vc-addon-wak_vc_jumbotron.left .wak-jumbotron,
  .wak-vc-addon.wak-vc-addon-wak_vc_jumbotron.special .wak-jumbotron {
    height: $jumbotron-height__desktop;
  }

  .wak-jumbotron-wrapper,
  .wak-jumbotron-clipped {
    width: 50%;
  }

  .wak-vc-addon.wak-vc-addon-wak_vc_jumbotron.left-close {
    height: $jumbotron-height__desktop;
    align-items: flex-end;

    .wak-jumbotron-clipped {
      width: 100%;
      height: $jumbotron-height__desktop;
      max-width: $container__max-width;
      margin: 0 auto;
    }

    .wak-jumbotron {
      height: $jumbotron-height__desktop;
    }

    .wak-jumbotron-content-wrapper {
      max-width: 670px;
    }

    &.has-breadcrumbs {
      height: $jumbotron-height__desktop--breadcrumb;
    }
  }

  .wak-jumbotron-breadcrumbs {
    display: block;
  }

  .wak-jumbotron-breadcrumbs-mobile {
    display: none;
  }
}

// > 1024
@media only screen and (min-width: map-get($breakpoints, lg)) {
  .wak-vc-addon-wak_vc_jumbotron {
    &.right,
    &.special {
      .wak-jumbotron-clipped {
        padding-left: 100px;
        // padding-right: 100px;
      }
    }

    &.left .wak-jumbotron-clipped {
      padding-right: 100px;
      // padding-left: 100px;
    }
  }

  .wak-jumbotron-clipped {
    max-width: math.div($container__max-width, 2);
  }

  .wak-vc-addon.wak-vc-addon-wak_vc_jumbotron.left-close {
    align-items: center;
  }
}

// event template
.page-template-page-special-events-dark,
.page-template-page-special-events {
  .wak-vc-addon.wak-vc-addon-wak_vc_jumbotron {
    background-color: $black;
  }

  .wak-jumbotron-content-title {
    display: flex;
  }

  .wak-jumbotron-content-title > span {
    flex: 0;
    display: inline-block;
    margin: 0 8px;
    padding: 24px 0;
    border-top: 3px solid $gold;
    border-bottom: 3px solid $gold;
    background-image: none;
    font-weight: 300;
  }

  .wak-vc-addon-wak_vc_jumbotron {
    &.left-close {
      .wak-jumbotron-image {
        opacity: .75;
      }
    }
  }
}