@import "variables";

.wak-vc-addon-wak_vc_image_and_copy_full_bg {
  width: 100%;
  position: relative;
  
  &.bg-green{
    background-color: $light-green;
  }
   
  &.bg-magenta{
    background-color: $magenta;

    h2,p{
      color: #fff;
    }
  }
   
  &.bg-orange{
    background-color: $yellow;
  }

  .content-image-wrapper {
    width: 100%;
    max-width: $container__max-width;
    padding: 0 $container__gutter;
    margin: 0 auto;
  }
}

.wak-image-copy-full-bg-image {
  display: block;
  width: 100%;
}

.wak-image-copy-full-bg-image-wrapper {
  margin-top: 70px;
}

.wak-image-copy-full-bg-content-pre-title {
  display: block;
  font-size: $h6__font-size;
  line-height: $h6__line-height;
  font-weight: $h6__font-weight;
}

.wak-image-copy-full-bg-content-title {
  color: inherit;
}

.wak-image-copy-full-bg-content-button-wrapper {
  margin-top: map-get($spacers, 3);
}

.wak-image-copy-full-bg-content-wrapper {
  margin-bottom: 40px;
}

// > 768
@media only screen and (min-width: map-get($breakpoints, md)) {
  .wak-image-copy-full-bg-content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 40px;
  }
}

// > 1024
@media only screen and (min-width: map-get($breakpoints, lg)) {
  .wak-image-copy-full-bg-content-wrapper {
    padding-right: 30px;
    padding-left: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &.position-right {
      padding-right: 100px;
      padding-left: 15px;
    }
  }
}

// Event template

.page-template-page-special-events-dark,
.page-template-page-special-events {
  .wak-vc-addon-wak_vc_image_and_copy_full_bg {

    &.bg-green{
      background-color: $black;

      p {
        color: #fff;
      }
    }

    &.bg-orange{
      background-color: $gold;

      h2, p {
        color: $black;
      }

      .button {
        border: 1px solid $black;
      }
    }
  }

  .wak-image-copy-full-bg-content-title {
    display: inline-block;
    padding-top: 10px;
    color: $gold;
    font-weight: 300;
    text-transform: uppercase;
    border-top: 6px solid $dark-green;
  }
}