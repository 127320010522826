@import "variables";

.wak-vc-addon.wak-vc-addon-wak_vc_two_column_copy_and_stats {
  width: 100%;
  max-width: $container__max-width;
  padding: 0 $container__gutter;
  margin: 0 auto;
}

.wak-tc-copy-stats-content-wrapper {
  margin-bottom: 40px;

  // > 768
  @media only screen and (min-width: map-get($breakpoints, md)) {
    margin-bottom: 0;
  }
  
}

.wak-tc-copy-stats-content-content {
  max-width: 470px;

  > *:last-child {
    margin-bottom: 0;
  }
}

.wak-tc-copy-stats-content-title {
  font-size: $h3__font-size;
  line-height: $h3__line-height;
  margin-bottom: 20px;
  max-width: 470px;
}