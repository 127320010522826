.site-header--nav {
  position: relative;
  display: none;
  padding: 0 15px;

  &.active {
    display: block;
  }
}

.header-donate-search-mobile {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  .donate-btn-wrapper {
    margin-right: 20px;
  }
}

.header-donate-search-desktop {
  display: none;
}

.header-nav__menu-bar {
  margin: 0;

  & > li {
    border-top: 3px solid $dark-green;
  }
}

.header-nav__menu-bar > li > span,
.header-nav__menu-bar > li > a,
.header-nav__main > a,
.header-nav__sub > li > a {
  display: block;
  padding: 10px 0;

  &:hover {
    color: $dark-green;
  }
}

.header-nav__main,
.header-nav__sub > li {
  border-top: 1px solid #EAEAF3;
}

.header-nav__panel--trigger {
  position: relative;
  padding-right: 15px !important;
  cursor: pointer;

  svg {
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    transform:translateY(-50%);
  }

  &.active {
    svg {
      transform: rotate(180deg);
      margin-top: -3px;
    }
  }
}

.playstore-icon {
  display: inline-block;
  margin-bottom: -1px;
}

.header-nav__panel {
  // show/hide
  display: none;

  &.active {
    display: block;
  }
}

.header-nav--overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  background-color: rgba(0, 0, 0, .5);
  display: none;
  z-index: 10;

  &.active {
    display: block;
  }
}

@media only screen and (min-width: map-get($breakpoints, md)) {
  .header-donate-search-mobile {
    display: none;
  }

  .header-donate-search-desktop {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    max-width: 416px;

    .donate-btn-wrapper {
      margin-right: 20px;
    }
  }

  .site-header--nav {
    display: block;
  }

  .header-nav {
    border-top: 1px solid $black;
    max-width: 1170px;
    margin: 0 auto;
  }

  .header-nav__menu-bar {
    display: flex;

    & > li {
      border-top: none;
      margin-right: 20px;
      position: relative;

      &:last {
        margin-right: 0;
      }

      &.playstore-last-item {
        margin-left: auto;
        margin-right: 0;
      }
    }
  }

  .playstore-icon {
    margin-bottom: -2px;
  }

  .header-nav__panel {
    position: absolute;
    top:100%;
    margin-left: -10px;
    background-color: $white;
    width: auto;

    a {
      white-space: nowrap;
      padding-left: 10px !important;
      padding-right: 10px !important;
    }
  }

  .header-nav__main {
    border-top: 3px solid $dark-green;
  }

  .header-nav__panel--trigger {
    svg {
      margin-top: 1px;
    }

    &.active {
      svg {
        margin-top: -2px;
      }
    }
  }
}