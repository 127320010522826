.accordion-multi-body {
  .vc_row {
    margin-bottom: 32px;

    &:last-child {
      margin-bottom: 0;
    }

    .wak-vc-addon-video {
      margin-top: 0;
    }
  }

  .wak-vc-addon-wak_vc_quote {
    .row {
      margin-left: 0;
      margin-right: 0;
    }

    .quotation-marks {
      margin-bottom: 12px;
    }
  }


  .wpb_text_column .wpb_wrapper blockquote {
    margin-top: 0;
    padding-top: 0;
    padding-left: 0;
    background-image: none;
  }
}