.search-form {
  flex-grow: 1;
  display: flex;
}

input.search-field {
  width: 100%;
  height: 100%;
  padding: 9px 10px;

  &:focus {
    outline: none;
  }
}

.search-submit {
  cursor: pointer;
  width: 44px;
  height: 44px;
  flex-shrink: 0;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus {
    outline: none;
  }
}