#root {
  overflow: hidden;
}

.wpb_wrapper {
  padding: 20px 0px;
  @media (min-width: 1024px) {
    padding: 30px 0px;
  }
}

article .vc_row:first-child .wpb_wrapper {
  padding-top: 0;
}

.vc_row {
  &.wpb_row.vc_row-fluid {
    display: flex;
    flex-wrap: wrap;
  }

  &:after,
  &:before {
    width: 0px;
  }
}

// .vc_row-fluid {
//   margin-right: 0px !important;
//   margin-left: 0px !important;
//   padding-right: 0px !important;
//   padding-left: 0px !important;
// }

// .vc_column-inner {
//   padding: 0px !important;
// }

.no-padding {
  padding: 0px;
}

.no-padding-top {
  padding-top: 0px;
}

.no-padding-left {
  padding-left: 0px;
}

.no-padding-right {
  padding-right: 0px;
}

.container {
  min-width: 100%;
  padding: 0px;
}

.wpb_text_column {
  padding: 0 25px;
  max-width: 1170px;
  margin: 0 auto;

  @media (min-width: 768px) {
    padding: 0 25px;
  }

  @media (min-width: 1024px) {
    padding: 0 128px;
  }

  @media (min-width: 1167px) {
    padding: 0 200px;
  }
}

// .stats-and-copy-wrapper {
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
// }

.wpb_video_widget {
  .wpb_wrapper {
    margin: 0 auto;
  }
}

.no-results-cta {
  padding: 14px 38px;
  display: inline-block;
  margin: 50px 0 100px !important;
}

.wpb_text_column, .wpb_video_widget {
  &.wpb_content_element {
    margin-bottom: 0;
  }
}

.content-wrapper {
  max-width: $container__max-width;
  margin-left: auto;
  margin-right: auto;
}

// event template
.page-template-page-special-events-dark, 
.page-template-page-special-events {
  .wpb_text_column {
    max-width: 1200px;
    padding: 0 15px;

    h1 {
      display: inline-block;
      padding-top: 10px;
      color: $gold;
      font-weight: 300;
      text-transform: uppercase;
      border-top: 6px solid $dark-green;
      font-size: 4.6rem;
      line-height: 5.4rem;
    }

    h2 {
      display: inline-block;
      padding-top: 10px;
      color: $gold;
      font-weight: 300;
      text-transform: uppercase;
      border-top: 6px solid $dark-green;
      font-size: 3.5rem;
      line-height: 4.3rem;
    }

    h3 {
      color: $gold;
      font-size: 2rem;
      line-height: 2.6rem;
    }
  }
}