.header {
  position: relative;
  background-color: $white;
  z-index: 20;
}

.header-container {
  padding: 20px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.site-logo {
  width: auto;
  height: 48px;

  svg {
    width: auto;
    height: 100%;
  }

  a:hover {
    color: $black;
  }
}

.burger-menu {
  display: block;
  padding: 0;
  margin: 0;
  color: $orange;
  background-color: transparent;
  width: 40px;
  flex-shrink: 0;

  &:hover {
    color: $orange;
    background-color: transparent;
  }

  &--open {
    display: block;
  }

  &--close {
    display: none;
  }

  &.active {
    .burger-menu {
      &--open {
        display: none;
      }

      &--close {
        display: block;
      }
    }
  }
}

// event template
.page-template-page-special-events-dark, 
.page-template-page-special-events {
  .header {
    color: $white;
    background-color: $black;

    a {
      color: $white;
    }

    .donate-btn,
    .search-submit {
      color: $black;
      background-color: $gold;

      &:hover {
        background-color: $dark-green;
      }
    }

    input.search-field {
      border: 1px solid $white;
    }
  }
}

@media only screen and (min-width: map-get($breakpoints, md)) {
  .site-logo {
    height: 64px;
  }

  .burger-menu {
    display: none;
  }

  // event template
  .page-template-page-special-events-dark, 
  .page-template-page-special-events {
    .header-nav {
      border-top: 1px solid $white;
    }

    .header-nav__panel a {
      color: $black;

      &:hover {
        color: $dark-green;
      }
    }
  }
}