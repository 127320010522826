@import "variables";

.wak-vc-addon-wak_vc_image_and_copy {

  &.bg-green{
      background-color: $mid-green;
  }
   &.bg-magenta{
      background-color: $magenta;
  }
   &.bg-orange{
      background-color: $orange;
  }

  width: 100%;
  max-width: $container__max-width;
  padding: 0 $container__gutter;
  margin: 0 auto;
  .row:last-child {
    margin-bottom: 40px;
  }
}

.wak-image-copy-content-wrapper {
  &.position-left {
    margin-top: 40px;
  }

  &.position-bottom {
    margin-top: 40px;
  }

  &.position-right {
    margin-bottom: 40px;
  }
}

.wak-image-copy-content-content {
  ul {
    margin-left: 20px;
    list-style: none;
  }

  li {
    padding: 0 0 0 5px;
    margin-bottom: 15px;
    position: relative;

    &:before {
      content: "\2022";
      color: $dark-green;
      display: block;
      position: absolute;
      left: -21px;
      font-size: 20px;
    }
  }
}

.wak-image-copy-content-button-wrapper {
  margin-top: map-get($spacers, 3);
}

.wak-image-copy-image-wrapper img {
  display: block;
  width: 100%;
}

// 768
@media only screen and (min-width: map-get($breakpoints, md)) {
  .wak-image-copy-content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &.position-left {
      margin-top: 0;
    }

    &.position-bottom {
      margin-top: 0;
    }

    &.position-right {
      margin-bottom: 0;
    }
  }
}

// > 1024
@media only screen and (min-width: map-get($breakpoints, lg)) {
  .wak-vc-addon-wak_vc_image_and_copy {
    .row:last-child {
      margin-bottom: 0;
    }
  }

  .wak-image-copy-content-wrapper {
    //padding-top: 100px;

    &.position-left {
      padding-right: 80px;
      padding-left: 100px;
    }

    &.position-right {
      padding-right: 100px;
      padding-left: 80px;
    }
  }
}

// event template
.page-template-page-special-events-dark, 
.page-template-page-special-events {
  .wak-image-copy-content-title {
    span {
      display: inline-block;
      padding-top: 10px;
      color: $gold;
      font-weight: 300;
      text-transform: uppercase;
      border-top: 6px solid $dark-green;
    }
  }

  // > 1024
  @media only screen and (min-width: map-get($breakpoints, lg)) {
    .wak-image-copy-content-wrapper {
      &.position-left {
        padding-right: 15px;
      }

      &.position-right {
        padding-left: 15px;
      }
    }
  }
}
